.container {
  width: 1200px;
  margin: 0 auto;
}

.event-links {
  border: 2px solid #2795c9;
  padding: 0.75rem 3rem;
  border-radius: 100px;
  font-size: 1.2rem;
  transition: all 0.2s ease-in-out;
  color: #fff;
  font-weight: 700;
  /* background-color: #359ad4; */
  margin: 0 0.4rem;
  margin-top: 20px;
}

.event-links1 {
  padding: 0.25rem 3rem;
  border-radius: 100px;
  font-size: 1rem;
  transition: all 0.2s ease-in-out;
  color: #fff;
  background-color: #164f78;
  border: none;
  margin: 0 0.4rem;
  margin-top: 20px;
}

.event-links-disabled {
  background-color: #0f942e;
  opacity: 0.6;
  cursor: default !important;
}

.event-links-disabled:hover {
  background-color: #0f942e !important;
  opacity: 0.6 !important;
  cursor: default !important;
}

.otp-links-disabled {
  /*background-color: #0f942e;*/
  opacity: 0.6;
  cursor: default !important;
  border: none;
}

.otp-links-disabled:hover {
  /*background-color: #0f942e !important;*/
  background-color: rgb(27, 30, 31);
  opacity: 0.6 !important;
  cursor: default !important;
}

.event-links:hover {
  background-color: #2795c9 !important;
  /* border-color: rgb(57, 62, 63);  */
}

.event-links1:hover {
  /* background-color: rgb(38, 41, 43);
  border-color: rgb(57, 62, 63); */
  background-color: hsl(205, 69%, 18%);
  border-color: rgb(57, 62, 63);
}

.event-name {
  font-size: 1.5vw;
  color: #fff;
  margin-bottom: 1vw;
}

button {
  background-color: transparent;
  color: #fff;
  cursor: pointer;
}

button:focus {
  outline: none;
}

.event-links-active {
  background-color: #2795c9;
  border: none;
  color: #fff;
  font-weight: 500;
}

.event-links-active:hover {
  color: #fff;
}

.event-links-disabled {
  opacity: 0.5;
  cursor: default !important;
}

a.event-links:hover {
  background-color: #990090;
  text-decoration: none;
  color: #fff;
}

.hero-title {
  font-size: 3.2rem;
  text-align: center;
  margin: 3rem 0;
}

.hero-link-container {
  display: flex;
  flex-wrap: wrap;
  margin: 2vw 10%;
  justify-content: center;
}

.hero-link-container .event-links {
  margin: 1rem;
}

.e-card-container {
  margin: 3rem auto;
  width: 90%;
}

.e-card {
  display: flex;
  border-radius: 1rem;
  overflow: hidden;
  background-color: hsl(199, 86%, 34%);
  margin: 1em 0;
  max-height: unset !important;
  box-shadow: 0 0 16px 0px hsl(199, 68%, 40%);
  width: 90%;
  margin: 2vw auto !important;
  padding: 1vw;
  cursor: pointer;
  height: 100%;
}

.e-card .event-links {
  font-size: 1rem;
  padding: 0.5rem 3rem;
}

.event-info {
  font-size: 1rem;
  color: #fff;
  font-weight: 700;
  margin-bottom: 15px;
}

@media (min-width: 600px) {
  .e-card {
    max-height: 500px;
  }
}

@media (min-width: 1200px) {
  .e-card {
    max-height: 300px;
    margin: 2em 0;
  }
}

.e-card:hover {
  box-shadow: none;
  transform: scale(1.015);
  /* box-shadow: 0 0 15px; */
  box-shadow: 0 0 17px 0px #0ac9ef;

  /* background-color: #99009099; */
  -webkit-transition: background-color 1000ms ease-out;
  -ms-transition: background-color 1000ms ease-out;
  transition: background-color 1000ms ease-out;
}

/* .e-card-text {
  padding: 0 0 3vw;
} */
@media (min-width: 1200px) {
  .e-card-text {
    padding: 0;
  }
}

.e-card-text p {
  margin: 0 auto 2vw;
  font-size: 2vw;
  color: #fff;
  line-height: 2.5rem;
}

.e-card img {
  width: 60%;
  height: auto;
  margin: auto;
  /* border-radius: 100vw; */
}

.e-card-buttons .link-event {
  padding: 0.7vw 2vw;
  margin: 0 auto;
  min-width: 50%;
}

.e-card-buttons button:hover {
  /* background-color: #990090;
    color: #131313; */
  /* font-size: 1.2rem; */
}

.popup-container {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.9);
  transition: all 0.3s ease-in-out;
  color: #000 !important;
  z-index: 1100;
}

.popup {
  background-color: #164f78 !important;
  /* border-radius: 2rem; */
  padding: 2rem;
  z-index: 1101;
  width: 670px;
  /* box-shadow: 0 0 0 100vw rgba(0, 0, 0, 0.774); */
  margin: auto;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: calc(100vh - 100px);
  overflow-y: auto;
}

.popup h1 {
  margin-top: 0;
}

@media (max-width: 600px) {
  .popup {
    width: 95%;
    /* border-radius: 5vw; */
  }
}

@media screen and (max-width: 1200px) {
  .container {
    width: 95%;
  }

  .e-card-img {
    width: 300px;
    min-height: 300px;
  }

  /* .e-card-buttons .link-event{
        padding: 10px 10px;
        margin: -10px;
        min-width: 100%;
      } */
}

.primary {
  background-color: #8bc7f5;
}

/* @media screen and (max-width: 768px) {
  .e-card-buttons .link-event{
    padding: 0px 5px;
    margin: -25px;
    min-width: 100%;
  }
} */

@media screen and (max-width: 750px) {
  html {
    font-size: 80%;
  }

  .e-card {
    /* flex-direction: column; */
  }

  .e-card-img {
    width: 100%;
    max-height: 200px;
  }

  /* .e-card-text {
        padding: 2rem;
      } */
  .e-card-text p {
    margin-bottom: 1rem;
  }
}

@media (max-width: 600px) {
  .e-card-buttons .link-event {
    margin-bottom: 7vw;
    padding: 2vw 4vw;
  }

  .e-card {
    margin: 5vw auto !important;
  }

  .event-name {
    font-size: 5vw;
    margin-bottom: 4vw;
  }

  /* .popup{
        top: 2%;
       transform: translateY(0%);
      } */
  .e-card-container {
    top: 0;
    /* height: 1000px; */
    /* overflow: hidden; */
  }
}

/* @-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 1px rgba(44, 172, 204, 0.4);
  }
  70% {
      -webkit-box-shadow: 0 0 0 7px rgba(44, 180, 204, 0.685);
  }
  100% {
      -webkit-box-shadow: 0 0 0 1px rgba(44, 185, 204, 0.562);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 1px rgba(44, 204, 196, 0.4);
      background-color: black;
      box-shadow: 0 0 0 1px rgba(44, 180, 204, 0.4);
  }
  70% {
      -moz-box-shadow: 0 0 0 7px rgba(44, 204, 204, 0.486);
      background-color: rgba(74, 104, 105, 0.671);
      box-shadow: 0 0 0 7px rgba(44, 199, 204, 0.055);
  }
  100% {
      -moz-box-shadow: 0 0 0 1px rgba(44, 172, 204, 0.452);
      background-color: black;
      box-shadow: 0 0 0 1px rgba(44, 172, 204, 0.445);
  }
} */

#style-2::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

#style-2::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

#style-2::-webkit-scrollbar-thumb {
  border-radius: 0px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #990090;
}

.pop-container {
  margin-top: 200px;
}

.pop-container {
  position: relative;
  display: inline;
}

.pop-content {
  visibility: hidden;
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -80px;
  margin-bottom: 10px;
  z-index: 100;
  color: gray;
  width: 230px;
  text-align: center;
  padding: 12px 16px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  background-color: #fff;
  opacity: 0;
  transform: scale(0.5);
  transition: all 0.5s;
}

span.warn {
  color: yellow;
  display: inline-block;
}

span.warn::after {
  content: "\E62C";
  position: absolute;
}

.hidden {
  visibility: visible;
  transform: scale(1);
  opacity: 1;
}

.text-content::after {
  content: " ";
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: white transparent transparent transparent;
}

.text-content {
  padding: 4px 0px 12px;
  text-align: left;
}

.actions {
  text-align: right;
}

.actions button {
  padding: 3px 7px;
  margin-left: 8px;
  border-radius: 4px;
  background-color: #fff;
  background-image: none;
  display: inline-block;
  color: rgba(0, 0, 0, 0.65);
  outline: 0px;
  border: 1px solid #d9d9d9;
  transition: all 0.3s;
}

.actions button:hover {
  color: #fff;
  background-color: #1890ff;
  border-color: #1890ff;
}
