.nav-bar {
  color: white;
  padding: 2vw 2vw 2vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
  height: 10vh;
}

.nav-bar.scrolled {
  background-color: #000 !important;
  transition: background-color 200ms linear;
}

.nav-links {
  flex: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.nav-links .nav-link,
.login-btn {
  color: #fff;

  text-decoration: none;
  font-weight: normal;
  margin: 0 1%;
  padding: 5px 20px;
  /* border: 1px solid rgb(187, 187, 187); */
  border-radius: 20px;
  font-size: 100%;
}

.nav-link:after,
.login-btn:after {
  background: none repeat scroll 0 0 transparent;
  bottom: -0.5vw;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: relative;
  /* background: rgba(13, 134, 150, 0.493); */
  background-color: #990090;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
  color: #fff;
}

.nav-link a:hover {
  color: #fff;
}

.nav-link:hover:after,
.login-btn:hover:after {
  width: 100%;
  color: #fff;
  left: 0;
}

.nav-link:hover {
  color: #fff;
}

.login-btn-div {
  display: flex;
  flex-direction: row;
}

.login-btn {
  /* background-color: rgba(13, 134, 150, 0.493); */
  background-color: #990090;
  border-radius: 2vw;
}

.login-btn-div a:hover {
  text-decoration: none;
  color: #fff;
}

.selected {
  color: #fff !important;
  font-weight: bold;
  /* background-color: rgba(22, 164, 189, 0.767); */
  background-color: #990090;
}

.main-logo b {
  font-size: 180%;
}

.navbar-toggle-icon {
  display: none;
}

.mobile-menu {
  font-family: Roboto;
  z-index: 10000;
  position: fixed;
  display: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.801);
  box-shadow: 0 8px 32px 0 rgba(0, 1, 20, 0.37);
  backdrop-filter: blur(12.5px);
  -webkit-backdrop-filter: blur(12.5px);
  font-size: 4vw;
  /* border-radius: 10px; */
}

.close-menu {
  position: absolute;
  top: 30px;
  right: 30px;
}

.mobile-menu ul {
  list-style: none;
  position: absolute;
}

.mobile-menu ul li {
  margin: 20px auto;
  display: flex;
  justify-content: center;
}

.mobile-menu ul li a {
  color: white;
  text-decoration: none;
  font-weight: bold;
  padding: 5px 20px;
  /* border: 1px solid rgb(187, 187, 187); */
  /* border-radius: 20px; */
}

@media only screen and (max-width: 1100px) {
  .nav-links {
    display: none;
  }

  .navbar-toggle-icon {
    display: inline;
    margin-left: 20px;
  }
}

@media only screen and (max-width: 609px) {
  .mobile-menu {
    display: flex;
  }
}

@media (max-width: 600px) {
  .main-logo img {
    height: 42px;
  }

  .nav-bar {
    padding: 3vw 5vw;
    /* background-color: #000; */
  }

  .login-btn {
    border-radius: 5vw;
  }

  .login-btn-div {
    max-height: 10vh;
  }
}

#notification {
  cursor: pointer;
}

#notification:hover {
  background: #333;
}

.desktop-only {
  display: block;
}

@media (max-width: 600px) {
  .desktop-only {
    display: none;
  }
}

@media only screen and (max-width: 280px) {
  .main-logo img {
    height: 31px;
  }
  .login-btn-div {
    align-items: center;
  }
}
