/* KEYFRAMES */

@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(359deg);
  }
}

@keyframes spin3D {
  from {
    transform: rotate3d(0.5, 0.5, 0.5, 360deg);
  }
  to {
    transform: rotate3d(0, 0, 0, 0deg);
  }
}

@keyframes configure-clockwise {
  0% {
    transform: rotate(0);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes configure-xclockwise {
  0% {
    transform: rotate(45deg);
  }
  25% {
    transform: rotate(-45deg);
  }
  50% {
    transform: rotate(-135deg);
  }
  75% {
    transform: rotate(-225deg);
  }
  100% {
    transform: rotate(-315deg);
  }
}

@keyframes pulse {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0.25;
    transform: scale(0.75);
  }
}

/* GRID STYLING */

* {
  box-sizing: border-box;
}

body {
  min-height: 100vh;
  background-color: #1d2630;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
}

.preloader {
  /* display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; */
  width: 100%;
  height: 100vh;
  background: #000b2b;
  z-index: 99999;
  overflow: hidden;
}

.spinner-box-loader {
  /* width: 100%;
    height: 70%; */
  margin: 300px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

.leo-loader {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.blue-orbit-loader {
  width: 400px;
  height: 400px;
  border: 3px solid #91daffa5;
  animation: spin3D 3s linear 0.2s infinite;
}

.green-orbit-loader {
  width: 340px;
  height: 340px;
  border: 3px solid #91ffbfa5;
  animation: spin3D 2s linear 0s infinite;
}

.red-orbit-loader {
  width: 270px;
  height: 270px;
  border: 3px solid #ffca91a5;
  animation: spin3D 1s linear 0s infinite;
}

.white-orbit-loader {
  width: 150px;
  height: 150px;
  border: 3px solid #ffffff;
  animation: spin3D 10s linear 0s infinite;
}

.w1-loader {
  transform: rotate3D(1, 1, 1, 90deg);
}

.w2-loader {
  transform: rotate3D(1, 2, 0.5, 90deg);
}

.w3-loader {
  transform: rotate3D(0.5, 1, 2, 90deg);
}

.loader-container {
  max-width: 600px;
  width: 90%;
  margin: 0 auto;
}

.loader-container img {
  width: 100%;
}
