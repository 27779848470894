/*
background - #1A1A1D
text '20' color - #C3073F
font - Segoe UI
*/

* {
  box-sizing: border-box;
}

/* elements */

body {
  font-family: "Segoe UI", sans-serif;
  background: #1a1a1d;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 16px;
}

h1 {
  font-weight: bold;
  margin-bottom: 30px;
  font-size: 45px !important;
}

.para {
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 20px !important;
  letter-spacing: 0.5px !important;
  margin: 20px 0 30px !important;
  line-height: 30px !important;
}

.link {
  color: #1b96f3;
  font-size: 14px;
  text-decoration: none !important;
  margin: 15px 0px 40px 0px;
  font-weight: 500 !important;
}

.container-block {
  background: #000 !important;
  border-radius: 10px !important;
  box-shadow: 0px 0px 3vw rgba(71, 197, 255, 0.247) !important;
  position: absolute !important;
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%) !important;
  overflow: hidden !important;
  width: 800px !important;
  max-width: 100% !important;
  min-height: 550px !important;
}

footer {
  bottom: -20% !important;
}

/* input */

input[type="text"]::placeholder {
  color: #5bb7fde7;
  font-weight: 400 !important;
}

input[type="email"]::placeholder {
  color: #5bb7fde7;
  font-weight: 400 !important;
}

input[type="password"]::placeholder {
  color: #5bb7fde7;
  font-weight: 400 !important;
}

input:focus {
  outline: none !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #000b2b inset !important;
  -webkit-text-fill-color: white !important;
}

/*input:-webkit-internal-autofill-selected {*/
/*  appearance: menulist-button;*/
/*  !* background-color: rgb(232, 240, 254) !important; *!*/
/*  background-color: #1b96f3 !important;*/
/*  background-image: none !important;*/
/*  color: -internal-light-dark(black, white) !important;*/
/*}*/

/* form */
.form-container-block {
  position: absolute;
  height: 100%;
  transition: all 0.6s ease-in-out;
}

.form-container-block form {
  background: #000;
  padding: 0 50px;
  color: #6699cc;
  align-items: center;
  text-align: center;
}

.form-container-block form input {
  color: #fff;
  font-size: 15px;
  letter-spacing: 1.5px;
  caret-color: #fff;
  font-weight: 500;
}

.form-container-block input {
  background: #000;
  border-color: #000;
  border-bottom: 1px solid #6699cc;
  padding: 12px 15px;
  margin: 12px 0;
  width: 100%;
}

.sign-up-container::-webkit-scrollbar {
  width: 5px;
  background-color: black;
}

.sign-up-container::-webkit-scrollbar-thumb {
  background-color: #6699cc;
}

/* buttons */
.button {
  border-radius: 2vw;
  border: 2px solid #6699cc;
  box-shadow: 0px 0px 1vw #6699cc;
  background: #000;
  color: #6699cc;
  font-size: 1.2vw;
  font-weight: normal;
  padding: 12px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
  margin-top: 20px;
}

button:active {
  transform: scale(0.95);
  box-shadow: 0px 0px 10px #1b96f3;
}

button:focus {
  outline: none !important;
}

button:hover {
  cursor: pointer;
}

button .ghost {
  background: transparent;
  border-color: #fff;
}

#signup-btn {
  margin-top: 40px;
  margin-bottom: 40px;
}

#signin-btn {
  margin-top: 60px;
}

/* icons */
.sign-up-container i {
  display: none;
}

.fa-arrow-circle-left {
  font-size: 20px;
  color: #032c55;
  position: absolute;
  top: 5%;
  left: 5%;
}

.fa-arrow-circle-left:hover {
  cursor: pointer;
}

/* signIn container */
.sign-in-container {
  left: 0;
  width: 50%;
  z-index: 2;
  margin-top: 50px;
}

/* signUp container */
.sign-up-container {
  left: 0;
  width: 50%;
  opacity: 0;
  z-index: 1;
  overflow-x: hidden;
  overflow-y: auto;
  /* overflow: auto; */
}

.sign-up-container input:last-of-type {
  margin-bottom: 10px;
}

/* overlay container */
.overlay-container {
  position: absolute;
  top: 0;
  left: 50%;
  height: 100%;
  width: 50%;
  overflow: hidden;
  transition: transform 0.6s ease-in-out;
  z-index: 100;
}

.overlay {
  background: #000;
  background: linear-gradient(to right, #990090, #2e6674, #990090) no-repeat 0 0 /
    cover;
  color: #170d22;
  position: relative;
  left: -100%;
  height: 100%;
  width: 200%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

.overlay-panel {
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 40px;
  height: 100%;
  width: 50%;
  text-align: center;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

.overlay-panel input {
  background: #000;
  border-radius: 20px;
  border: 2px solid #1b96f3;
  padding: 12px 15px;
  margin: 8px 0;
  width: 100%;
  color: #1b96f3;
  font-size: 15px;
  letter-spacing: 1.5px;
}

.overlay-right {
  right: 0%;
  transform: translateX(0);
}

.overlay-left {
  transform: translateX(-20%);
}

.register-now {
  display: none;
}

/* Errors */
.error {
  font-size: 12px !important;
  color: rgb(255, 94, 94);
  font-weight: 600;
  text-align: left;
  margin-left: 10px;
  letter-spacing: 0.4px;
}

#drop-down {
  color: #999;
  background: transparent;
  padding: 14px 15px 12px !important;
  letter-spacing: 1.5px;
  outline: none;
  height: 100%;
  width: 100%;
  outline: none;
  padding-left: 15px;
  /* border-radius: 5px; */
  border: none;
  border-bottom: 2px solid hsl(199, 68%, 40%);
  /* border-bottom-width: 2px; */
  font-size: 17px;
  transition: all 0.3s ease;
  color: #fff;
}

#drop-down:focus {
  color: #fff;
  background-color: #000b2b;
  border-bottom: 2px solid hsl(199, 68%, 40%);
  outline: none;
}

#year {
  border: 1px solid #5bb7fd;
  border-radius: 10px;
  box-shadow: 0px 0px 10px #1b96f3;
}

#drop-down:hover {
  cursor: pointer;
}
.main-error {
  margin-top: 30px;
  margin-bottom: -20px;
  text-align: center;
}

/* //Animation */
/* Move signin to right */
.container-block.right-panel-active .sign-in-container {
  transform: translateX(100%);
}

/* move overlay to left */
.container-block.right-panel-active .overlay-container {
  transform: translateX(-100%);
}

/* Bring Sign Up over sign in */
.container-block.right-panel-active .sign-up-container {
  transform: translateX(100%);
  opacity: 1;
  z-index: 10;
}

/* Move overlay back to right */
.container-block.right-panel-active .overlay {
  transform: translateX(50%);
}

.container-block.right-panel-active .overlay-left {
  transform: translateX(0);
}

.container-block.right-panel-active .overlay-right {
  transform: translateX(20%);
}

@media only screen and (max-width: 1080px) {
  /* footer */
  footer {
    bottom: 0% !important;
  }
}

@media only screen and (max-width: 800px) {
  /* container */
  .overlay-container {
    display: none;
  }

  .container-block {
    width: 500px !important;
    top: 50% !important;
    height: 75%;
    transform: translate(-50%, -45%) !important;
  }

  .form-container-block {
    width: 100%;
    padding: 0px 30px;
  }

  .sign-up-container {
    opacity: 0;
    transform: rotateY(-180deg);
    backface-visibility: hidden;
  }

  /* footer */
  footer {
    bottom: 0% !important;
  }

  /* icon */
  .sign-up-container i {
    display: block;
    top: 5%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 30px;
  }

  /* Heading */
  h1 {
    margin-bottom: 30px !important;
    font-size: 60px !important;
  }

  /* Button */
  .button {
    border-radius: 20px !important;
    border: 2px solid #1b96f3;
    font-size: 15px;
    margin-top: 0px;
  }

  #signin-btn {
    margin-top: 20px;
  }

  /* Form */
  .signup-form {
    margin-top: 80px;
  }

  /* Input */
  .form-container-block input {
    padding: 6px 7.5px;
    margin: 10px 0;
  }

  .form-container-block form input {
    font-size: 20px !important;
  }

  /* Others */
  .link {
    display: block;
    font-size: 18px;
    margin: 15px 0px 20px 0px !important;
  }

  .error {
    font-size: 15px !important;
  }

  .register-now {
    display: block;
    position: absolute;
    left: 50%;
    bottom: 10%;
    transform: translate(-50%, -50%);
    text-align: center !important;
  }

  .register-now p {
    font-size: 20px !important;
    margin: 10px 0px 0px;
    color: #1b96f3;
  }

  #drop-down {
    background: #359ad4;
    padding: 6px 7.5px 6px !important;
    display: block;
    margin: 9px 0px !important;
    font-weight: 500 !important;
    font-size: 20px;
    width: 100%;
    text-align: left;
  }

  #year {
    border: 1px solid #5bb7fde7;
    border-radius: 5px;
    box-shadow: 0px 0px 10px #1b96f3;
    width: 100%;
    padding-left: 10px;
  }
}

@media only screen and (max-width: 600px) {
  /* Container */
  .container-block {
    width: 350px !important;
    height: 80% !important;
    top: 55% !important;
  }

  .form-container-block {
    width: 100%;
    padding: 0px 0px;
  }

  /* footer */
  footer {
    bottom: -20% !important;
  }

  /* Icon */
  .sign-up-container i {
    font-size: 25px;
  }

  /* Heading */
  h1 {
    font-size: 40px !important;
  }

  /* Button */
  .button {
    border: 1px solid #1b96f3;
    font-size: 12px;
  }

  /* Form-Input */
  .form-container-block form input {
    font-size: 15px !important;
  }

  /* Others */
  .link {
    font-size: 15px;
  }

  .register-now {
    bottom: 2%;
    margin-bottom: 20px;
  }

  .register-now p {
    font-size: 15px !important;
    margin: 10px 0px 10px !important;
  }

  .error {
    font-size: 12px !important;
  }

  #drop-down {
    font-size: 15px;
  }
}

@media only screen and (max-width: 500px) {
  /* container */
  .container-block {
    width: 300px !important;
    height: 75% !important;
    top: 50% !important;
  }

  body {
    overflow: auto;
  }

  .form-container-block {
    width: 100%;
    padding: 0px 30px;
  }

  /* footer */
  footer {
    bottom: -5% !important;
  }

  /* Heading */
  h1 {
    margin-bottom: 20px !important;
    font-size: 30px !important;
  }

  /* icon */
  .sign-up-container i {
    font-size: 25px;
  }

  /* button */
  .button {
    font-size: 10px;
    padding: 10px 20px;
    border-radius: 10px !important;
  }

  /* form */
  .signup-form {
    margin-top: 80px;
  }

  .form-container-block form {
    padding: 0 0px;
  }

  /* form-input */
  .form-container-block input {
    padding: 6px 7.5px;
    margin: 10px 0;
  }

  .form-container-block form input {
    font-size: 15px !important;
  }

  .link {
    display: block;
    font-size: 12px;
    margin: 15px 0px 20px 0px !important;
  }

  .register-now {
    bottom: 2%;
  }

  .register-now p {
    font-size: 12px !important;
    margin: 10px 0px 10px !important;
  }

  .error {
    font-size: 12px !important;
  }

  #drop-down {
    font-weight: 400 !important;
    font-size: 15px;
  }

  .wrapper .title {
    font-size: 35px !important;
  }
}

@media only screen and (max-width: 380px) {
  .wrapper .title {
    font-size: 50px !important;
  }
}

@media only screen and (max-width: 325px) {
  .wrapper .title {
    font-size: 40px !important;
  }

  /* container */
  .container-block {
    position: relative;
    width: 250px !important;
    top: 55% !important;
    height: 80% !important;
  }

  .sign-in-container {
    height: 100% !important;
  }

  /* body */
  body {
    overflow: visible;
  }

  /* footer */
  footer {
    bottom: -22% !important;
  }

  /* icon */
  .sign-up-container i {
    font-size: 20px;
  }

  /* button */
  .button {
    border-radius: 10px !important;
    font-size: 10px;
    padding: 10px 20px;
  }

  /* input */
  .form-container-block form input {
    font-size: 12px !important;
  }

  .register-now p {
    font-size: 10px !important;
    margin: 10px 0px 10px !important;
  }

  .error {
    font-size: 9px !important;
  }
}

/* Radiance Login */

::selection {
  background: hsl(199, 86%, 34%);
  color: #fff;
}
.wrapper {
  overflow-x: hidden;
  width: 90%;
  max-width: 700px;
  max-height: 80vh;
  background: #fff;
  padding: 30px;
  margin: 20px auto;
  /* box-shadow: 0 0 16px 0px #990090; */
  box-shadow: 0 0 16px 0px hsl(199, 68%, 40%);
  border-radius: 5px;
  background-color: hsl(199, 86%, 34%);
  background: transparent;
  border: 3px solid hsl(199, 68%, 40%);
}
.wrapper .title-text {
  display: flex;
  width: 200%;
}
.wrapper .title {
  color: #fff;
  width: 50%;
  font-size: 35px;
  font-weight: 600;
  text-align: center;
  transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.wrapper .slide-controls {
  position: relative;
  display: flex;
  height: 50px;
  width: 100%;
  overflow: hidden;
  margin: 30px 0 10px 0;
  justify-content: space-between;
  /* border: 1px solid lightgrey; */
  border-radius: 5px;
}

.wrapper .form-container {
  width: 100%;
  overflow: hidden;
}
.form-container .form-inner {
  display: flex;
  width: 200%;
}
.form-container .form-inner form {
  width: 50%;
  transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.slide-controls .slide {
  height: 100%;
  width: 100%;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  line-height: 48px;
  cursor: pointer;
  z-index: 1;
  transition: all 0.6s ease;
}
.slide-controls label.signup {
  color: #000;
}
.slide-controls .slider-tab {
  position: absolute;
  height: 100%;
  width: 50%;
  left: 0;
  z-index: 0;
  border-radius: 5px;
  /* background: -webkit-linear-gradient(left, #a445b2, #fa4299); */
  background-color: hsl(199, 68%, 40%);
  transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

#signup:checked ~ label.login {
  color: #000;
}

#login:checked ~ label.login {
  cursor: default;
  user-select: none;
}

#login:checked ~ label.signup {
  color: #000;
}

input[type="radio"] {
  display: none;
}
#signup:checked ~ .slider-tab {
  left: 50%;
}
#signup:checked ~ label.signup {
  color: #fff;
  cursor: default;
  user-select: none;
}

.form-inner form .field {
  height: 50px;
  width: 100%;
  margin-top: 20px;
}
.form-inner form .field input {
  background: transparent;
  height: 100%;
  width: 100%;
  outline: none;
  padding-left: 15px;
  /* border-radius: 5px; */
  border: none;
  border-bottom: 2px solid hsl(199, 68%, 40%);
  /* border-bottom-width: 2px; */
  font-size: 17px;
  transition: all 0.3s ease;
  color: #fff;
}
.form-inner form .field input:focus {
  border-color: #fc83bb;
  /* box-shadow: inset 0 0 3px #fb6aae; */
}
.form-inner form .field input::placeholder {
  color: #999;
  transition: all 0.3s ease;
}
form .field input:focus::placeholder {
  color: #b3b3b3;
}

.form-inner form .pass-link {
  margin-top: 5px;
}

.form-inner form .pass-link a,
.form-inner form .signup-link a {
  color: #fff;
  cursor: pointer;
  font-weight: 700 !important;
}
.form-inner form .pass-link a:hover,
.form-inner form .signup-link a:hover {
  text-decoration: underline;
}

form .custom-btn {
  height: 50px;
  width: 100%;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
}
form .custom-btn .custom-btn-layer {
  height: 100%;
  width: 300%;
  position: absolute;
  left: -100%;
  /* background: -webkit-linear-gradient(
    right,
    #a445b2,
    #fa4299,
    #a445b2,
    #fa4299
  ); */
  border-radius: 5px;
  transition: all 0.4s ease;
}
form .custom-btn:hover .custom-btn-layer {
  left: 0;
}
form .custom-btn input[type="submit"] {
  height: 100%;
  width: 100%;
  z-index: 1;
  position: relative;
  background-color: #000b2b;
  /* background-color: #032c55; */
  border: 3px solid hsl(199, 68%, 40%);
  color: #fff;
  padding-left: 0;
  border-radius: 5px;
  font-size: 20px;
  font-weight: 700;
  cursor: pointer;
  border-radius: 999px;
}
form .custom-btn input[type="submit"]:hover {
  background-color: hsl(199, 68%, 40%);
}
@media only screen and (min-width: 600px) {
  .login-btn-div {
    max-width: 100%;
  }
}
